.file-upload {
    background-color: #ffffff;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px 10px 10px;

    .file-upload-btn {
        width: 100%;
        margin: 0;
        color: #fff;
        background: #216462;
        border: none;
        padding: 10px;
        border-radius: 4px;
        border-bottom: 4px solid #216462;
        transition: all .2s ease;
        outline: none;
        text-transform: uppercase;
        font-weight: 700;
    }

    .file-upload-btn:hover {
        background: #216462;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }

    .file-upload-btn:active {
        border: 0;
        transition: all .2s ease;
    }

    .file-upload-content {
        display: block;
        width: 100%;
        text-align: left;
        padding: 0;
        overflow:scroll; 
        max-height:150px;

        label.file-name {
            float: left;
            width: 100%;
        }
        
        label.remove-btn {
            padding: 10px 5px 5px 5px;
            float: right;
        }
    }

    .file-upload-input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
    }

    .image-upload-wrap {
        margin-top: 10px;
        border: 4px dashed #216462;
        position: relative;
    }

    .image-dropping,
    .image-upload-wrap:hover {
        // background-color: #36a7a3;
        // border: 4px dashed #ffffff;
    }

    .image-title-wrap {
        padding: 0 15px 15px 15px;
        color: #222;
    }

    .drag-text {
        text-align: center;
    }

    .drag-text h4 {
        font-weight: 100;
        text-transform: uppercase;
        color: #216462;
        padding: 60px 0;
    }

    .file-upload-image {
        max-height: 200px;
        max-width: 200px;
        margin: auto;
        padding: 20px;
    }

    .remove-image {
        width: 200px;
        margin: 0;
        color: #fff;
        background: #cd4535;
        border: none;
        padding: 10px;
        border-radius: 4px;
        border-bottom: 4px solid #b02818;
        transition: all .2s ease;
        outline: none;
        text-transform: uppercase;
        font-weight: 700;
    }

    .remove-image:hover {
        background: #c13b2a;
        color: #ffffff;
        transition: all .2s ease;
        cursor: pointer;
    }

    .remove-image:active {
        border: 0;
        transition: all .2s ease;
    }
}