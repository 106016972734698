.admin-layout {
    .set-color {
        color: #216462;
    }

    .title {
        color: #828282;
    }

    .small-desc {
        color: #828282;
    }

    a {
        color: #216462;
    }

    .breaker {
        margin-bottom: 100px;
    }

    .sticky-footer {
        background-color: #ffffff;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 50px;
        margin-bottom: 0px;
        z-index: 2;
    }

    .card-img-top {
        width: 100%;
        height: 15vw;
        object-fit: contain;
    }

    .drag-text {
        img {
            width: 200px !important;
        }
    }
}