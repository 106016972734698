body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  td, th{
    white-space: nowrap;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.right-float-patch {
  float: right;
}

.extra-action {
  display: none;
}

.sticky-footer {
  background-color: #ffffff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  margin-bottom: 0px;
  z-index: 2;
}

@media (max-width: 600px) {
  .right-float-patch {
    float: unset;
  }

  .main-content-wrap-avoide {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .extra-action {
    white-space: nowrap;
    display: flex !important;
    width: 90vw;
    max-height: 5em;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .main-content-wrap {
    padding: 0 1rem !important;
  }

  .parchdisplay {
    display: none !important;
  }

  .breadcrumb {
    h1 {
      font-weight: 900;
      font-size: 1.5em;
      padding-top: 0.5em;
    }
  }
}