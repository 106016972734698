.profile {
    .nav-pills .nav-link {
        border-radius: 0.25rem;
        background-color: #EBEBEB;
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #216462;
        border: none;
    }
}