.search-form {
    
    .form-label {
        text-align: left;
        font-family: Montserrat;
        letter-spacing: 0px;
        color: #216462;
        opacity: 1;
    }

    .text-primary {
        color: #216462 !important;
        font-weight: bold;
        padding: .5rem 1rem !important;
        margin: 1rem 1.5rem 0 0 !important;
    }

    .btn-padding {
        .btn {
            padding: 0.4rem 0.8rem;
            margin: 1em 1.5rem 0 0;
            font-size: 14px;
            border-radius: 0;
        }
    }

    .active {
        svg {
            path {
                stroke: #ffffff;
                fill: #ffffff;
            }
        }
    }

    .breaker {
        margin-bottom: 100px;
    }
    .padding-top-norm{
        margin-top: 1em;
        font-weight: 700;
    }
    .sticky-footer {
        background-color: #ffffff;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 50px;
        margin-bottom: 0px;
        z-index: 2;
        @media (max-width: 600px) {
            .custom-margin-bottom{
                margin-left: 0rem;
            }
        }
        @media (min-width: 600px) {
            .custom-margin-bottom{
                margin-left: 3rem;
            }
        }
    }
    @media (min-width: 600px) {
        .fixing-class{
            position: unset !important;
            display: contents !important;
        }
    }
    @media (max-width: 600px) {
        .fixing-slider{
            overflow-y: hidden;
            overflow-x: scroll;
        }
    }
    .shape-fix{
        display: block !important;
    }
}