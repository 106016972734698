.report {
    .set-color {
        color: #216462;
    }

    .title {
        color: #828282;
    }

    .small-desc {
        color: #828282;
    }

    a {
        color: #216462;
    }

    .breaker {
        margin-bottom: 100px;
    }

    .sticky-footer {
        background-color: #ffffff;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 50px;
        margin-bottom: 0px;
        z-index: 2;
    }

    .text-cirle-dot {
        padding: 0px 8px;
        border-radius: 50%;
        background-color: #216462;
        margin-right: 5px;
    }

    .card-img-overlay:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: 100%;
        background: transparent;
        z-index: 0;
    }

    .btn-primary,
    .btn-outline-primary {
        border-color: #216462;
        color: #216462;
    }

    .btn-primary:hover,
    .btn-outline-primary:hover {
        background: #216462;
        box-shadow: 0 8px 25px -8px #216462;
        border-color: #216462;
        color: #ffffff;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show>.btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #216462;
        border-color: #216462;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem #21646248;
    }

    .btn-primary:focus,
    .btn-outline-primary:focus {
        box-shadow: none;
        box-shadow: 0 8px 25px -8px #21646248;
    }

    .pagination .active a {
        background: #216462;
        color: white;
        border-color: #216462;
    }

    .table td {
        padding: 0.3rem;
    }

}