.admin-users {
    .link {
        margin-right: 5px;
    }

    .form-control-underlined {
        border-width: 0;
        border-bottom-width: 1px;
        border-radius: 0;
        padding-left: 0;
    }
}

.user-details {
    .scroller {
        height: 200px;
        overflow-y: scroll;
    }

    .card-body {
        .card-title {
            font-size: 1rem;
        }

        .simple-card-title {
            h3 {
                color: #216462 !important;
            }

            border-bottom: 1px solid #216462;
        }
    }
}