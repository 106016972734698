.custom-table {
    max-height: 60vh;
    .table .thead-dark th {
        color: #fff;
        background-color: #216462;
        border-color: #216462;
    }
    .pagination .active a {
        background: #216462;
        color: white;
        border-color: #216462;
    }    
    .fixed_header th, .fixed_header td {
      padding: 5px;
      text-align: left;
    }
    .table-responsive{
        height:60vh;  
        overflow:scroll;
    }
    thead tr:nth-child(1) th{
        position: sticky;
        top: 0;
        z-index: 10;
    }
}