.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #216462;
    background-color: transparent;
    border-bottom: 5px solid;
    border-bottom-color: #216462;
}

a {
    color: #216462;
    text-decoration: none;
    background-color: transparent;
}

.dashboard {
    .greet-card {
        background-color: #B6D7D3;
    }

    .greeting-text {
        color: #216462;
    }

    .login-time {
        color: #216462;
    }

    .featured-header {
        background-color: #216462;
        color: #FFFFFF;
        border-radius: 10px 10px 0 0;
    }

    .card-icon-bg-primary [class^="i-"] {
        color: #B6D7D3;
    }

    .text-primary {
        color: #216462 !important;
    }

    .tab-content {
        padding: 1rem;
        min-height: 300px;
        max-height: 300px;
        overflow-y: scroll;
        width: 100%;
    }
}